import { Link, NavLink } from "react-router-dom";
import { settings } from "../../../../defaults";

const Navigation = () => {
  const menu = () => {
  var x = document.getElementById("topNavigationMenu");
  if (x.className === "topnav") {
    x.className += " responsive";
  } else {
    x.className = "topnav";
  }         
  }         
  const closeUp = () => {
  var x = document.getElementById("topNavigationMenu");
  if (x.className !== "topnav") {
    x.className = "topnav";
  }         
  }         
  return (
    <>      
      <nav className="flex bg-black header" style={{backgroundColor: settings?.headerBg ? settings.headerBg : null}}>
        <div className="topnav" id="topNavigationMenu">
            <Link to={"/"} className="active" style={{background: settings?.logoBg ? settings.logoBg : null}}>
              <img
                className="h-10"
                src={settings.logo}
                alt={settings.title}
	        style={{width: '4rem', height: '4rem'}}
              />
            </Link>
            <a href="javascript:void(0);" class="icon2" onClick={menu}>
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#fff" />
              </svg>
          </a>
           
          <a href="javascript:void(0);" class="icon" onClick={menu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-white text-[0.937rem] font-bold cursor-pointer"
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </a>
          <NavLink
            to={"/politics"}
            className="text-white text-[0.937rem] font-bold"
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
	    onClick={closeUp}
          >
            Politics
          </NavLink>
          <NavLink
            to={"/entertainment"}
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
            className="text-white text-[0.937rem] font-bold"
	    onClick={closeUp}
          >
            Entertainment
          </NavLink>
          <NavLink
            to={"/business"}
            className="text-white text-[0.937rem] font-bold"
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
	    onClick={closeUp}
          >
            Business
          </NavLink>
          <NavLink
            to={"/science"}
            className="text-white text-[0.937rem] font-bold"
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
	    onClick={closeUp}
          >
            Science
          </NavLink>
          <NavLink
            to={"/health"}
            className="text-white text-[0.937rem] font-bold"
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
	    onClick={closeUp}
          >
            Health
          </NavLink>
          <NavLink
            to={"/travel"}
            className="text-white text-[0.937rem] font-bold"
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
	    onClick={closeUp}
          >
            Travel
          </NavLink>
          <NavLink
            to={"/sport"}
            className="text-white text-[0.937rem] font-bold"
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
	    onClick={closeUp}
          >
            Sports
          </NavLink>
          <NavLink
            to={"/crime"}
            className="text-white text-[0.937rem] font-bold"
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
	    onClick={closeUp}
          >
            Crime
          </NavLink>
          <NavLink
            to={"/ecology"}
            className="text-white text-[0.937rem] font-bold"
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
	    onClick={closeUp}
          >
            Ecology
          </NavLink>
          <NavLink
            to={"/opinion"}
            className="text-white text-[0.937rem] font-bold"
	    onClick={closeUp}
	      style={{
		      color: settings?.headerTextColor ? settings.headerTextColor : null
	      }}
          >
            Opinion
          </NavLink>
	  {/*
          <NavLink
            to={"/world"}
            className="text-white text-[0.937rem] font-bold"
          >
            World
          </NavLink>
          <NavLink
            to={"/politics"}
            className="text-white text-[0.937rem] font-bold"
          >
            Politics
          </NavLink>
          <NavLink
            to={"/bussiness"}
            className="text-white text-[0.937rem] font-bold"
          >
            Bussiness
          </NavLink>
          <NavLink
            to={"/opinion"}
            className="text-white text-[0.937rem] font-bold"
          >
            Opinion
          </NavLink>
          <NavLink
            to={"/health"}
            className="text-white text-[0.937rem] font-bold"
          >
            Health
          </NavLink>
          <NavLink
            to={"/entertaiment"}
            className="text-white text-[0.937rem] font-bold"
          >
            Entertaiment
          </NavLink>
          <NavLink
            to={"/styles"}
            className="text-white text-[0.937rem] font-bold"
          >
            Styles
          </NavLink>
          <NavLink
            to={"/travel"}
            className="text-white text-[0.937rem] font-bold"
          >
            Travel
          </NavLink>
          <NavLink
            to={"/sports"}
            className="text-white text-[0.937rem] font-bold"
          >
            Sports
          </NavLink>
	*/}
        </div>

        <div className="flex basis-1/4 justify-evenly items-center mr-4" style={{display: "none"}}>
          <NavLink
            to={"/watch"}
            className="text-white text-[0.937rem] font-bold"
          >
            Watch
          </NavLink>
          <NavLink
            to={"/listen"}
            className="text-white text-[0.937rem] font-bold"
          >
            Listen
          </NavLink>
          <NavLink
            to={"/live-tv"}
            className="text-white text-[0.937rem] font-bold"
          >
            Live TV
          </NavLink>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-white cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </div>
          <NavLink
            to={"/login"}
            className="text-white p-1 text-[0.937rem] border rounded border-white font-bold"
          >
            Log In
          </NavLink>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
