import { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import {
  CardTextOnly,
  MidCard,
  BigCard,
  SmallHorizontalCard,
  ColumnHead,
  SmallAdvertisement,
} from "../../../index";
import { settings } from "../../../../defaults";

  const defMidCards = [
    {
      link: "/your-link-url",
      imageSrc: "images/FirstSection/news2.jpg",
      text: "Bear attacks in Japan are at a record high. Climate change and an aging population worsening the problem",
      tag: "",
    },

    // Add more cards as needed
  ];
  const defTextOnly = [
    {
      link: "/your-link-url",
      text: "Texas Supreme Court blocks pregnant woman from emergency abortion",
    },
    {
      link: "/your-link-url",
      text: "HIV vaccine trial dubbed ‘last roll of the dice’ halted over poor results",
    },
    {
      link: "/your-link-url",
      text: "Bill Nye explains how climate change is affecting our pocket books",
    },
    {
      link: "/your-link-url",
      text: "Tesla failed at battery swapping. Stellantis says it may have the secret",
    },
    {
      link: "/your-link-url",
      text: "‘Not done yet.’ Cristiano Ronaldo scores on his 1,200th game",
    },
  ];
  const defBigCard = [
    {
      link: "/your-link-url",
      imageSrc: "images/SecondSection/Feature1.jpg",
      text: "Extinction Rebellion climate activists dye Venice’s Grand Canal green in COP 28 protest",
      tag: "",
    },

    // Add more cards as needed
  ];
  const defSmallHorizontalCard = [
    {
      link: "/your-link-url",
      imageSrc: "images/SecondSection/Feature2.jpg",
      text: "Her mother vanished when she was 1. Some 40 years later, a phone call from a stranger helped her understand why",
      tag: "",
    },
    {
      link: "/your-link-url",
      imageSrc: "images/SecondSection/Feature3.jpg",
      text: "The grove robbers flooding the market with fake extra virgin olive oil",
      tag: "",
    },

    // Add more cards as needed
  ];
  const defSmallHorizontalCard3 = [
    {
      link: "/your-link-url",
      imageSrc: "images/SecondSection/n1.jpg",
      text: "Texas Supreme Court blocks pregnant woman from emergency abortion",
      tag: "",
    },
    {
      link: "/your-link-url",
      imageSrc: "images/SecondSection/n2.jpg",
      text: "Analysis: Rishi Sunak is picking a fight on an issue that he probably cannot win",
      tag: "",
    },
    {
      link: "/your-link-url",
      imageSrc: "images/SecondSection/n3.jpg",
      text: "Tesla failed at battery swapping. Stellantis says it may have the secret",
      tag: "",
    },
    {
      link: "/your-link-url",
      imageSrc: "images/SecondSection/n4.jpg",
      text: "‘Massive coup’: Top golfer who once criticized LIV golf joins for $300M",
      tag: "",
    },

    // Add more cards as needed
  ];
const SecondSection = () => {
	const [midCards, setMidCards] = useState(null);
	const [textOnly, setTextOnly] = useState(null);
	const [bigCard, setBigCard] = useState(null);
	const [smallHorizontalCard, setSmallHorizontalCard] = useState(null);
	const [smallHorizontalCard3, setSmallHorizontalCard3] = useState(null);
	const params = useParams();
	
	useEffect(() => {
		fetchData();
		//fetchTextData();
	}, [params]);

const fetchData = () => {
        var style = params?.styleId ? params.styleId : settings?.style;
        var url = '';
        style = style.toLowerCase();
        switch(style) {
                case 'glamour':
                case 'tabloid':
                case 'expose':
                case 'investigation':
                case 'analytical':
                case 'op ed':
                case 'dramatic':
                case 'in depth':
                case 'critical':
                case 'narrative':
                case 'reveal':
                        url = encodeURI('/api/news?style='+style);
                        break;
                default:
                        url = '/api/news?style=investigation';
                        break;
        }

	url = '/api/get/news';

        fetch(url)
        .then(res => res.json())
        .then(data => {
                var big = {};
                var mid = [];
                var text = [];
                var small = [], small3 = [];
		var processed = [];
                for (let art of data) {
                        var item = {};
                        item.imageSrc = art?.image?.url;
                        item.text = art.title;
                        item.title = art.title;
                        item.summary = art.summary ? art.summary.replace(/<img [^>]*\/>/,'') : art.description;
                        item.description = art.description ? art.description.replace(/<img [^>]*\/>/,'') : art.summary;
                        item.tag = ''; //art.source;
                        item.link = art.link;
                        processed.push(item);
                }
                big = processed.slice(0,1);
                mid = processed.slice(1,2);
		small = processed.slice(2,6);
		small3 = processed.slice(6,12);

                text = processed.slice(12);

		setTextOnly(text);
		small = small.map((i) => {
			i.text = i.description; //i.summary ? i.summary : i.description;
			return i;
		});
		small3 = small3.map((i) => {
			i.text = i.description; //i.summary ? i.summary : i.description;
			return i;
		});
		setBigCard(big);
		setMidCards(mid);
		setSmallHorizontalCard(small);
		setSmallHorizontalCard3(small3);
        })
        .catch(e => console.error);
}
const fetchTextData = () => {
        var style = params?.styleId ? params.styleId : settings?.style;
        var url = '';
        style = style.toLowerCase();
        switch(style) {
                case 'glamour':
                case 'tabloid':
                case 'expose':
                case 'investigation':
                case 'analytical':
                case 'op ed':
                case 'dramatic':
                case 'in depth':
                case 'critical':
                case 'narrative':
                case 'reveal':
                        url = encodeURI('/api/news?textOnly=true&style='+style);
                        break;
                default:
                        url = '/api/news?textOnly=true&style=investigation';
                        break;
        }
	url = '/api/get/news?textOnly=true';
        fetch(url)
        .then(res => res.json())
        .then(data => {
                var big = {};
                var mid = [];
                var text = [];
                var small = [], small3 = [];
		var processed = [];
                for (let art of data) {
                        var item = {};
                        item.text = art.title;
                        item.title = art.title;
                        item.summary = art.summary ? art.summary.replace(/<img [^>]*\/>/,'') : art.description;
                        item.description = art.description ? art.description.replace(/<img [^>]*\/>/,'') : art.summary;
                        item.tag = ''; //art.source;
                        item.link = art.link;
                        processed.push(item);
                }
                text = processed.slice(0,7);

		setTextOnly(text);
        })
        .catch(e => console.error);
}

  return (
    <div className="flex flex-col md:px-8 md:flex-row">
      <div className="w-full md:w-1/3 p-4">
        <ColumnHead columnHeadTag="MORE TOP STORIES" />
        {midCards && midCards.map((card, index) => (
          <MidCard key={index} {...card} />
        ))}
        {textOnly && textOnly.map((card, index) => (
          <CardTextOnly key={index} {...card} />
        ))}
      </div>
      <div className="w-full md:w-1/3 p-4">
        <ColumnHead columnHeadTag="FEATURED" />
        {bigCard && bigCard.map((card, index) => (
          <BigCard key={index} {...card} />
        ))}
        {smallHorizontalCard && smallHorizontalCard.map((card, index) => (
          <SmallHorizontalCard key={index} {...card} />
        ))}
      </div>
      <div className="w-full md:w-1/3 p-4">
        <SmallAdvertisement
          link={"https://filmon.com"}
          imageSrc={"images/SecondSection/adv.jpg"}
          tag={"Advertisment"}
        />
        <div>
          {smallHorizontalCard3 && smallHorizontalCard3.map((card, index) => (
            <SmallHorizontalCard key={index} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
