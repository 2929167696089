import {
  Header,
  FirstSection,
  SecondSection,
  ThirdSection,
  FourthSection,
  FifthSection,
  SixthSection,
  SeventhSection,
  EightSection,
  NinthSection,
  TenthSection,
  NavFooter,
} from "../../components";

const Home = () => {
  return (
    <div>
      <Header />
      <div style={{background: '#fff', zIndex: 1, position: 'relative'}}>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
	  {/* <SeventhSection />
      <EightSection />
      <NinthSection />
      <TenthSection /> */}
      </div>
      <NavFooter />
    </div>
  );
};

export default Home;
