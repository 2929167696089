import { NavLink } from "react-router-dom";
import { settings } from "../../../../defaults";

const Footer = () => {
  const policies = [
    { url: "#", title: "Term of Use" },
    { url: "#", title: "Privacy Policy" },
//    { url: "to-cookie-setting", title: "Cookie Settings" },
/*    { url: "to-add-choices", title: "Ad Choices" },
    { url: "to-access-and-cc", title: "Accessibility & CC" },*/
    { url: "#", title: "About" },
//    { url: "to-newsletters", title: "Newsletters" },
//    { url: "to-transcripts", title: "Transcripts" },
  ];
  return (
    <>
      <div className="text-white text-sm flex flex-wrap items-center justify-start w-full mt-4 gap-4"
         style={{
		 color: settings?.footerTextColor ? settings.footerTextColor : null
	 }}
	  >
        {policies.map((policy, index) => (
          <NavLink key={index} to={policy.url} className="hover:text-gray-300">
            {policy.title}
          </NavLink>
        ))}
      </div>
      <p className="text-white text-sm mt-4"
         style={{
		 color: settings?.footerTextColor ? settings.footerTextColor : null
	 }}
	  >
        &copy; 2024 SwissX REDD UK ltd. All
        Rights Reserved.
      </p>
    </>
  );
};

export default Footer;
