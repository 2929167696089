import { Link, NavLink } from "react-router-dom";
import { settings } from "../../../../defaults";

const FooterFeatures = () => {
  const contentFooter = [
//    { src: "to-watch", title: "Watch" },
//    { src: "to-listen", title: "Listen" },
    { src: "https://filmon.com", title: "Live TV" },
  ];

  const socials = [
    { src: "/images/Footer/Facebook.png", url: "#" },
//    { src: "/images/Footer/Instagram.png", url: "#" },
//    { src: "/images/Footer/LinkedIn.png", url: "#" },
//    { src: "/images/Footer/Tiktok.png", url: "#" },
    { src: "/images/Footer/X.png", url: "#" },
  ];

  return (
    <div className="bg-black w-full py-8 px-5 flex flex-col items-start text-white border-t border-b border-gray-700"
	  style={{
		  position: 'relative',
		 color: settings?.footerTextColor ? settings.footerTextColor : null,
		  backgroundColor: settings?.footerBg ? settings.footerBg : null,
	  }}
    >
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center">
          <Link to={"/"}>
            <img
              src={settings.logo}
              alt=""
              className="w-auto h-8 mr-4"
            />
          </Link>
          <div className="font-bold text-2xl">{settings.title}</div>
        </div>
        <div className="flex items-center">
          {/*contentFooter.map((content, index) => (
            <NavLink
              key={index}
              to={content.src}
              className="text-white text-[0.937rem] font-bold ml-7"
            >
              {content.title}
            </NavLink>
          ))*/}
          <div className="border-r border-gray-500 w-1 h-6 mx-4"></div>
          <div className="text-white text-[0.937rem] font-bold"
	  style={{
		 color: settings?.footerTextColor ? settings.footerTextColor : null
	  }}
	  >Follow us</div>
          { socials.map((social, index) => (
            <NavLink
              key={index}
              to={social.url}
              className="text-white text-[0.937rem] font-bold mx-4"
            >
              <img src={social.src} className="h-full w-full" alt="" />
            </NavLink>
          ))} 
	  {/* <button className="border border-white rounded-xl ml-5 px-4 py-2 text-white hover:bg-gray-800">
            Login
          </button>
	  */}
        </div>
      </div>
    </div>
  );
};

export default FooterFeatures;
