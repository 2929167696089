import { Link } from "react-router-dom";
import Share from '../Share';

const CardTextOnly = (props) => {
	const { link, text, color } = props;
  return (<div>
    <a href={link} rel="noreferrer" target="_blank">
      <div className="flex flex-col justify-start mb-4">
        <hr className="flex-grow border-t-1 border-gray-300 mb-4" />
        <p
          className={`self-start decoration-1 hover:underline hover:underline-offset-4 text-base ${
            color ? "text-white" : ""
          }`}
        >
          {text}
        </p>
      </div>
    </a>
	  <Share {...props} small={true} mt="-10px" compact={true} />
  </div>);
};

export default CardTextOnly;
