import { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";

import { BannerAdvertisement } from "../../../common/Advertisement";
import { BigCard, MidCard, SmallHorizontalCard } from "../../../index";
import { settings } from "../../../../defaults";

  const midCardsDef = [
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/opinion.jpg",
      text: "Madonna’s ‘Celebration Tour’ is her most radical LGBTQ statement in decades",
      tag: "OPINION",
    },

    // Add more cards as needed
  ];
  const smallHorizontalCardDef = [
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/12.jpg",
      text: "More women of color in the US are choosing birth centers over hospitals",
      tag: "",
    },
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/13.jpg",
      text: "First tyrannosaur fossil discovered with its last meal perfectly preserved in stomach",
      tag: "",
    },

    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/14.jpg",
      text: "Salah scores 200th Liverpool goal to spark comeback win over Crystal Palace",
      tag: "",
    },
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/15.jpg",
      text: "Police officer borrows bike to chase drug dealer",
      tag: "",
    },

    // Add more cards as needed
  ];
  const bigCardDef = [
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/21.jpg",
      text: "American teenage phenom Ilia Malinin makes more figure skating history",
      tag: "",
    },

    // Add more cards as needed
  ];

  const smallHorizontalCard2Def = [
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/22.jpg",
      text: "Retired NYPD cops visited Martin Luther King Jr.’s birth home to take a picture. They caught an alleged would-be arsonist",
      tag: "",
    },
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/23.jpg",
      text: "The world’s most spectacular coastlines",
      tag: "",
    },

    // Add more cards as needed
  ];

  const midCards2Def = [
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/31.jpg",
      text: "li MacGraw remembers the ‘generosity’ of her late ‘Love Story’ costar Ryan O’Neal",
      tag: "",
    },

    // Add more cards as needed
  ];
  const smallHorizontalCard3Def = [
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/32.jpg",
      text: "‘Bone biographies’ reveal what life was like for everyday medieval people",
      tag: "",
    },
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/33.jpg",
      text: "Could the most magical time of year be so full of loneliness, anger and stress?",
      tag: "",
    },
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/34.jpg",
      text: "How to care for a Christmas cactus",
      tag: "",
    },
    {
      link: "/your-link-url",
      imageSrc: "images/ThridSection/35.jpg",
      text: "‘Sickest thing I’ve ever done’: Watch epic trick off skyscraper",
      tag: "",
    },

    // Add more cards as needed
  ];

const ThirdSection = () => {
	const [midCards, setMidCards] = useState(null);
	const [midCards2, setMidCards2] = useState(null);
	const [bigCard, setBigCard] = useState(null);
	const [smallHorizontalCard, setSmallHorizontalCard] = useState(null);
	const [smallHorizontalCard2, setSmallHorizontalCard2] = useState(null);
	const [smallHorizontalCard3, setSmallHorizontalCard3] = useState(null);
	const params = useParams();

	useEffect(() => {
		fetchData();
	}, []);
	useEffect(() => {
		fetchData();
	}, [params]);

const fetchData = () => {
        var style = params?.styleId ? params.styleId : settings?.style;
        var url = '';
        style = style.toLowerCase();
        switch(style) {
                case 'glamour':
                case 'tabloid':
                case 'expose':
                case 'investigation':
                case 'analytical':
                case 'op ed':
                case 'dramatic':
                case 'in depth':
                case 'critical':
                case 'narrative':
                case 'reveal':
                        url = encodeURI('/api/news3?style='+style);
                        break;
                default:
                        url = '/api/news3?style=investigation';
                        break;
        }
	url = '/api/get/news?s=20';
        fetch(url)
        .then(res => res.json())
        .then(data => {
                var big = {};
                var mid = [];
                var mid2 = [];
                var small = [], small2 = [], small3 = [];
                var processed = [];
                for (let art of data) {
                        var item = {};
                        item.imageSrc = art?.image?.url;
                        item.text = art.title;
                        item.title = art.title;
                        item.summary = art.summary ? art.summary.replace(/<img [^>]*\/>/,'') : art.description;
                        item.description = art.description ? art.description.replace(/<img [^>]*\/>/,'') : art.summary;
                        item.tag = ''; //art.source;
                        item.link = art.link;
                        processed.push(item);
                }
                big = processed.slice(0,1);
                mid = processed.slice(1,2);
                mid2 = processed.slice(2,3);
                small = processed.slice(3,10);
                small2 = processed.slice(10,13);
                small3 = processed.slice(13,20);
/*
                small = small.map((i) => {
                        i.text = i.summary ? i.summary : i.description;
                        return i;
                }); */
                small2 = small2.map((i) => {
                        i.text = i.summary ? i.summary : i.description;
                        return i;
                });
		/*
                small3 = small3.map((i) => {
                        i.text = i.summary ? i.summary : i.description;
                        return i;
                }); */
                setBigCard(big);
                setMidCards(mid);
                setMidCards2(mid2);
                setSmallHorizontalCard(small);
                setSmallHorizontalCard2(small2);
                setSmallHorizontalCard3(small3);
        })
        .catch(e => console.error);
}
	

  return (
    <>
      <BannerAdvertisement />
      <div className="flex flex-col mx-auto md:px-8 md:flex-row">
        <div className="w-full p-4 md:w-1/3">
          {midCards && midCards.map((card, index) => (
            <MidCard key={index} {...card} />
          ))}
          {smallHorizontalCard && smallHorizontalCard.map((card, index) => (
            <SmallHorizontalCard key={index} {...card} />
          ))}
        </div>
        <div className="w-full p-4 md:w-2/3">
          {bigCard && bigCard.map((card, index) => (
            <BigCard key={index} {...card} />
          ))}
          {smallHorizontalCard2 && smallHorizontalCard2.map((card, index) => (
            <SmallHorizontalCard key={index} {...card} />
          ))}
        </div>
        <div className="w-full p-4 md:w-1/3">
          {midCards2 && midCards2.map((card, index) => (
            <MidCard key={index} {...card} />
          ))}
          {smallHorizontalCard3 && smallHorizontalCard3.map((card, index) => (
            <SmallHorizontalCard key={index} {...card} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ThirdSection;
