import { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  PinterestShareCount,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TumblrShareCount,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';


const Share = ({ link, description, title, imageSrc, small, mt, compact }) => {
	const myId = crypto.randomUUID();

	useEffect(() => {
		var acc = document.getElementById(myId);
		var i;

			  acc?.addEventListener("click", function() {
    	//			this.classList.toggle("active");
				var arr = this.getElementsByClassName('arrow');
				  console.debug("ARROW", arr[0]);
    				var panel = this.nextElementSibling;
			    	if (panel.style.display === "flex") {
				      panel.style.display = "none";
					panel.style.height = '0px';
				      arr?.[0]?.classList.remove("up");
				      arr?.[0]?.classList.add("down");
			    	} else {
      					panel.style.display = "flex";
					panel.style.height = 'auto';
				      arr?.[0]?.classList.remove("down");
				      arr?.[0]?.classList.add("up");
    				}
  			});
	}, []);

  return (<>
      {compact && (
		<div id={myId} style={{
			cursor: 'pointer',
  			width: '100%',
  			textAlign: 'right',
  			border: 'none',
  			outline: 'none',
  			transition: '0.4s',
			marginTop: mt
		}} className="text-zinc-600 text-xs mt-1">
	      Share it <i id={myId+'arrow'} className="arrow down" />
		</div>

      )}
      <div style={{
	      display: compact ? 'none' : 'flex', 
  			transition: 'height 0.5s',
	      overflow: compact ? 'hidden' : undefined,
	      justifyContent: 'end', padding: '2px', marginTop: compact ? undefined : mt 
      }}> 
	<FacebookShareButton url={link} >
          <FacebookIcon size={small ? 24 : 32}  />
        </FacebookShareButton>
        <TwitterShareButton
          url={link}
          title={title}
        >
          <XIcon size={small ? 24 : 32}  />
        </TwitterShareButton>	  
        <TelegramShareButton
          url={link}
          title={title}
        >
          <TelegramIcon size={small ? 24 : 32}  />
        </TelegramShareButton>
        <WhatsappShareButton
          url={link}
          title={title}
          separator=":: "
        >
          <WhatsappIcon size={small ? 24 : 32}  />
        </WhatsappShareButton>	  
        <LinkedinShareButton url={link}>
          <LinkedinIcon size={small ? 24 : 32}  />
        </LinkedinShareButton>      
        <PinterestShareButton
          url={link}
          media={imageSrc}
        >
          <PinterestIcon size={small ? 24 : 32}  />
        </PinterestShareButton>
        <RedditShareButton
          url={link}
          title={title}
          windowWidth={660}
          windowHeight={460}
        >
          <RedditIcon size={small ? 24 : 32}  />
        </RedditShareButton>
        <EmailShareButton
          url={link}
          subject={title}
          body={description}
        >
          <EmailIcon size={small ? 24 : 32}  />
        </EmailShareButton>
      </div>
  </>);
};

export default Share;

