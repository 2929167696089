const rtwnewsSettings = {
	title: "RealTime Wire ",
	description: "RealTime Wire offers 24/7 live-streaming news coverage, integrated with real-time community chat and user-generated content. With a focus on live reporting, it provides a seamless multi-device experience and an interactive timeline to follow breaking stories as they unfold.",
	logo: "/logo.png",
	logoBig: "/logo512.png",
	headerBg: "white",
	footerBg: "white",
	headerTextColor: "black",
	footerTextColor: "black",
	style: 'critical'
};

export default rtwnewsSettings;
