import { settings } from '../../../../defaults';

const HeaderAdvertisement = () => {
  return (
    <>
      <div className="hidden md:block">
        <div className="h-64 bg-black flex items-center justify-center flex-col" style={{backgroundColor: settings?.headerBg ? settings.headerBg : null}}>
          <div className="cursor-pointer" style={{position: 'fixed', zIndex: 0 }}>
	  <a href="https://www.cnn.com" rel="noreferrer" target="_blank" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <img src="/images/Header/adv.jpg" alt="Advertisement image" />
            <p className="text-zinc-600 text-xs mt-1">
              Advertisement
            </p>
	  </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderAdvertisement;
