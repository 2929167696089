import { Link } from "react-router-dom";
import Share from '../Share';

const SmallHorizontalCard = (props) => {
	const { imageSrc, link, text, tag } = props;
  return (<>
    <a href={link} rel="noreferrer" target="_blank">
      <div className="flex mt-2 border-t pt-4">
        <div className="relative group w-1/4">
          <img
            className="w-full h-full object-cover transition-transform transform group-hover:scale-100 hover:grayscale-50"
            src={imageSrc}
            alt={tag}
          />
        </div>
        <h4 className="text-base self-start ml-2 decoration-1 hover:underline hover:underline-offset-4 w-3/4">
          {text}
        </h4>
      </div>
    </a>
      <Share {...props} small={true} compact={true} />
  </>);
};

export default SmallHorizontalCard;
