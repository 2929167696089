import { useState, useEffect } from 'react';

import {
  Header,
  NavFooter,
  SmallHorizontalCard,
  BannerAdvertisement,
  NinthSection,
  ColumnHead,
  SmallAdvertisement,
} from "../../components";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

const Article = () => {
  const [ article, setArticle ] = useState(null);
  const [ smallHorizontalCard, setSmallHorizontalCard] = useState(null);
  const params = useParams();
  const articleId = params?.articleId ? params.articleId : null;
  const navigate = useNavigate();

  const fetchData = () => {
         fetch(`/api/art/${articleId}`)
        .then(res => {
		if(res.status != 200) {
			throw('Page not found');
		}
		return res.json();
	})
        .then(art => {
		if(!art)
			return navigate('/');
                var item = {};
                item.imageSrc = art?.image?.url;
                item.text = art.title;
                item.title = art.title;
                item.summary = art.summary?.replace(/<img [^>]*\/>/,'');
                item.description = art.description?.replace(/<img [^>]*\/>/,'');
                item.tag = art.source;
                item.link = art.link;
                item.thisLink = art.thisLink;
		item.author = art.author;
		item.source = art.source;
		item.pubDate = art.pubDate;
		item.categories = art.categories;
		item.article = art.article?.replace('/\n/', '<br />');
                setArticle(item);
        })
        .catch(e => { 
		console.error(e);
		navigate('/');
	});
  }

  useEffect(() => {
    fetchData();
  }, [params]);

  return (
    <>
      {article && (<Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.description} />
        <meta name="keywords" content={article.categories.join(',')} />
	      {/* <meta name="author" content={} /> */}
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.description} />
        <meta property="og:image" content={article.imageSrc} />
        <meta property="og:url" content={article.thisLink} />
        <meta property="article:publisher" content={article.thisLink.replace(/^(https:\/\/[^\/]*\/).*$/, "$1")} />
	{article.categories.map(c => (
		<meta property="article:tag" content={c} />
	))}
	<meta property="article:section" content={"NEWS"} />
	<meta property="article:published_time" content={article.pubDate} />
	<meta property="article:modified_time" content={article.pubDate} />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={article.description} />
        <meta name="twitter:image" content={article.imageSrc} />
        <meta name="twitter:card" content={article.imageSrc} />	  
      </Helmet>)}
      <Header />
      <div style={{zIndex: 10, position: 'relative', background: '#fff' }}>
      <div className="flex flex-wrap">
	<article>
        <div className="w-full p-4 md:w-3/4">
          <div className="title">
	  {article && (
            <h1 className="text-4xl font-bold m-4 pb-4">
		  {article.title}
            </h1>
	  )}
          </div>
          <div className="pl-4 author flex">
	    {article && (
            <div className="author-name-date">
		    {/*              <div className="author-name text-base text-gray-600 pl-2">
                {article.author} {article.source && article.source !== '' ? `@ ${article.source}` : ''}
              </div>*/}
              <div className="publish-date text-base text-gray-600 pl-2 flex items-centre justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <span className="pr-2">
		  {Date(article.pubDate).toLocaleString() || 'recently'}
                </span>
              </div>
            </div>
            )}
          </div>
	  {article && (
          <div className="content pt-6 pl-4">
            <div className="image-box bg-gray-100">
	     {article.imageSrc && (<img src={article.imageSrc} className="w-full" alt="" style={{maxWidth: '80%'}}/>)}
              <div className="heading-tag text-xs text-gray-600 pt-5 drop-shadow-sm p-2 border-b border-gray-300">
		  {article.title}
              </div>
            </div>
            <h4 dangerouslySetInnerHTML={{ __html: article.description}}  style={{padding: '2rem'}} />
            <div className="article-text mt-4 mx-2 md:mx-16">
		  <hr />
              <p dangerouslySetInnerHTML={{ __html: article.summary}} />
            </div>
            <div className="article-text mt-4 mx-2 md:mx-16">
		  <hr />
              <p dangerouslySetInnerHTML={{ __html: article.article}} />
            </div>
		  {/*    <div style={{ padding: '1rem' }}>
              <p>
                <a href={article.link} target="_blank" rel="noreferrer" style={{textDecoration: 'underline'}}>
                  Read full story...
                </a>
                .
              </p>
	    </div> */}
          </div>
	  )}
        </div>
	</article>
        <div className="w-full p-4 md:w-1/4">
          <div className="mt-12 md:mt-p12.5rem">
	  {/*            <div>
              <ColumnHead columnHeadTag={'MORE FROM ' + article?.source?.toUpperCase()} />
            </div> */}
            <div>
              {smallHorizontalCard && smallHorizontalCard.map((card, index) => (
                <SmallHorizontalCard key={index} {...card} />
              ))}
            </div>
            <div className="mt-5">
              <SmallAdvertisement
                imageSrc={"/images/Article/adv.png"}
                tag={"Advertisement"}
                link={"/adv-link"}
              />
            </div>
          </div>
        </div>
      </div>
      </div>
      <NavFooter />
    </>
  );
};

export default Article;
