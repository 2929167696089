import { useState, useEffect } from 'react';
import { CardTextOnly, ColumnHead, MidCard } from "../../../index";

const FifthSection = () => {
  const [ sport, setSport ] = useState(null);
  const [ sportS, setSportS ] = useState(null);
  const [ entertainment, setEntertainment ] = useState(null);
  const [ entertainmentS, setEntertainmentS ] = useState(null);
  const [ opinion, setOpinion ] = useState(null);
  const [ opinionS, setOpinionS ] = useState(null);

  useEffect(() => {
	fetchData('sport', setSport, setSportS);
	fetchData('entertainment', setEntertainment, setEntertainmentS);
	fetchData('opinion', setOpinion, setOpinionS);
  }, []);

  const fetchData = (cat, setMiddle, setSmall) => {
        fetch(`/api/get/news/${cat}`)
        .then(res => res.json())
        .then(data => {
                var mid = [];
                var small = [];
                var processed = [];
                for (let art of data) {
                        var item = {};
                        item.imageSrc = art?.image?.url;
                        item.text = art.title;
                        item.title = art.title;
                        item.summary = art.summary ? art.summary.replace(/<img [^>]*\/>/,'') : art.description;
                        item.description = art.description ? art.description.replace(/<img [^>]*\/>/,'') : art.summary;
                        item.tag = ''; //art.source;
                        item.link = art.link;
                        processed.push(item);
                }
                mid = processed.slice(0,1);
                small = processed.slice(1,6);

                small = small.map((i) => {
                        i.text = i.description; //i.summary ? i.summary : i.description;
                        return i;
                });
                setSmall(small);
                setMiddle(mid);
        })
        .catch(e => console.error);
  }
	
  return (
    <div>
      <div className="flex flex-col flex-wrap md:px-8 md:flex-row">
        <div className="w-full p-4 sm:w-1/2 md:w-1/3">
          <ColumnHead columnHeadTag={"SPORT"} />
          {sport && sport.map((card, index) => (
            <MidCard key={index} {...card} />
          ))}
          {sportS && sportS.map((text, index) => (
            <CardTextOnly key={index} {...text} />
          ))}
        </div>
        <div className="w-full p-4 sm:w-1/2 md:w-1/3">
          <ColumnHead columnHeadTag={"ENTERTAINMENT"} />
          {entertainment && entertainment.map((card, index) => (
            <MidCard key={index} {...card} />
          ))}
          {entertainmentS && entertainmentS.map((text, index) => (
            <CardTextOnly key={index} {...text} />
          ))}
        </div>
        <div className="w-full p-4 sm:w-1/2 md:w-1/3">
          <ColumnHead columnHeadTag={"OPINION"} />
          {opinion && opinion.map((card, index) => (
            <MidCard key={index} {...card} />
          ))}
          {opinionS && opinionS.map((text, index) => (
            <CardTextOnly key={index} {...text} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FifthSection;
